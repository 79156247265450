const state = {
  fullScreen: false,
  rememberAcount: false,
  acountInfs: {},
  userInfs: {},
  subjectData: [],
  moudleNav: [],
  moudleMenuData: {},
  channelMenuData:[],
  innerChapterMenuData: [],
  classMask:"",
};
const getters = {
  getFullScreen(state) {
    return state.fullScreen;
  },
  getRememberAcount(state) {
    return state.rememberAcount;
  },
  getAcountInfs(state) {
    return state.acountInfs;
  },
  getUserInfs(state) {
    return state.userInfs;
  },
  getSubjectData(state) {
    return state.subjectData;
  },
  getMoudleNav(state) {
    return state.moudleNav;
  },
  getMoudleMenuData(state) {
    return state.moudleMenuData;
  },
  getChannelMenuData(state){
    return state.channelMenuData
  },
  getInnerChapterMenuData(state){
    return state.innerChapterMenuData
  },
  getClassMask(state){
    return state.classMask
  },
};
const actions = {};
const mutations = {
  setFullScreen(state, data) {
    state.fullScreen = data;
  },
  setRememberAcount(state, data) {
    state.rememberAcount = data;
  },
  setAcountInfs(state, data) {
    state.acountInfs = data;
  },
  setUserInfs(state, data) {
    state.userInfs = data;
  },
  setSubjectData(state, data) {
    state.subjectData = data;
  },
  setMoudleNav(state, data) {
    state.moudleNav = data;
  },
  setMoudleMenuData(state, data) {
    state.moudleMenuData = data;
  },
  setChannelMenuData(state,data){
    state.channelMenuData = data;
  },
  setInnerChapterMenuData(state,data){
    state.innerChapterMenuData = data;
  },
  setClassMask(state,data){
    state.classMask=data
  }
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
