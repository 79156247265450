<template>
  <div class="empty-box">
    <img :src="emptyImg" class="empty-img">
  </div>
</template>

<script>
export default {
  name:"emptyBox",
  data(){
    return {
      emptyImg:require('@/assets/image/empty.png')
    }
  }
}
</script>

<style lang="scss" scoped>
  .empty-box{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .empty-img{
      width: 10.1rem;
      height: 2.8rem;
    }
  }
</style>