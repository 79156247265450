

import common from './api/common';
import user from './api/user';
import index from './api/index';
import menu from './api/menu';
import errorBook from './api/errorBook';
import labrary from './api/labrary';



//设置个对象，就不用一个个暴露了，直接暴露对象
let api = {
    ...common,
    ...user,
    ...index,
    ...menu,
    ...errorBook,
    ...labrary
};

//暴露出这个对象
export default api;

