import Vue from 'vue'
import App from './App.vue'


import router from './router'
import store from './store'

import axios from 'axios'
import VueAxios from 'vue-axios'
import api from "@/api/index.js"
import ElementUI from 'element-ui'
import './theme/element/index.css'




import { customMessage } from "@/utils/public";
import MathJax from '@/utils/MathJax.js'
Vue.prototype.MathJax=MathJax;
import device from 'current-device'
if (device.mobile()) {
  console.log('移动手机')
  window.location.href="http://gzm.jingdian985.com/#/";
} else if (device.ipad()) {
  console.log('ipad端')
  window.document.title="精准学移动端"
} else if (device.desktop()) {
  console.log('桌面端')
}
 
// 禁止浏览器前进后退 另一部本在router的index.js中
window.addEventListener('popstate', function() {
  history.pushState(null, null, document.URL)
})

import loadingBox from "@/components/loadingBox";
import coustmEmpty from "@/components/coustmEmpty.vue";

import globalMethods from "@/mixins/globalMethods"

Vue.use(VueAxios, axios)
Vue.use(ElementUI);

Vue.prototype.$axios = axios
Vue.prototype.$api = api
Vue.prototype.$eventBus = new Vue()
Vue.prototype.$customMessage = customMessage

Vue.component('loadingBox', loadingBox)
Vue.component('coustmEmpty', coustmEmpty)

Vue.mixin(globalMethods)
window.addEventListener('popstate', function() {
  history.pushState(null, null, document.URL)
})
Vue.config.productionTip = false
new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
